import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React, {
  useContext,
  useState,
  useRef,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { TvMain } from '@powdr/components/tv';
import { AlertType, ThemeProps as Prop, StaticProfiles } from '@powdr/constants';
import { AppContext } from '@powdr/context';
import { getAlerts } from '@powdr/stores';
import { componentFactory, themeUtil } from '@powdr/utils';

import GlobalStyle from '../../utils/global-styles';

const SiteWrapper = styled.div`
  height: 100vh;
  background-color: ${() => themeUtil(Prop.BACKGROUND, null, StaticProfiles.TV)};
`;

function TvStaticLayout({
  data,
}) {
  const { defaultTheme } = useContext(AppContext);
  const dispatch = useDispatch();
  const useAlertData = useSelector((state) => state.alerts);
  const { data: alerts } = useAlertData;
  const alertIntervalRef = useRef();
  const alertIntervalFrequency = 3; // minutes
  const [isAlertOverlayActive, setAlertOverlayActive] = useState(false);
  const {
    name,
    displayTitle,
    relationships,
    displayTime,
    alternateTVLogo,
    hideDOR,
    isIgnoreAlertOverlay,
  } = data.tvData;
  const components = relationships?.tvComponents;

  // Get alerts on interval, kick off on component load
  useEffect(() => {
    dispatch(getAlerts());

    alertIntervalRef.current = setInterval(() => {
      dispatch(getAlerts());
    }, alertIntervalFrequency * 60000);

    return () => {
      clearInterval(alertIntervalRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // handle alert overlay and rotation pausing when needed
  useEffect(() => {
    if (alerts?.find((a) => a.type === AlertType.DANGER)) {
      setAlertOverlayActive(true);
    } else if (isAlertOverlayActive) setAlertOverlayActive(false);
  }, [alerts, isAlertOverlayActive]);

  return (
    <>
      <GlobalStyle theme={defaultTheme} />
      <SiteWrapper>
        <TvMain
          title={displayTitle || name}
          hideDOR={hideDOR}
          alternateTVLogo={alternateTVLogo}
          navPages={[]}
          activeItemData={components[0]}
          isAlertOverlayActive={isAlertOverlayActive && !isIgnoreAlertOverlay}
          alertData={{
            enabled: data?.tvData?.alertOverlayEnabled,
            message: data?.tvData?.alertMainMessage,
            subMessage: data?.tvData?.alertSubMessage,
            iconName: data?.tvData?.alertIconName,
            iconColor: data?.tvData?.alertIconColor,
          }}
          overlayAlerts={(alerts?.length > 0)
            ? alerts?.filter((a) => a.type === AlertType.DANGER)
            : []}
        >
          {componentFactory(
            components || [],
            undefined,
            undefined,
            {
              displayTime,
            },
          )}
        </TvMain>
      </SiteWrapper>
    </>
  );
}

TvStaticLayout.propTypes = {
  data: PropTypes.instanceOf(Object).isRequired,
};

export default TvStaticLayout;

export const query = graphql`
  query TvStaticLayout($fullPath: String){
    site {
      ...SiteMetadataQuery
    }
    tvData: taxonomyTermTvNavigation( field_navigation_link: { eq: $fullPath } ) {
      ...TvPageQuery
    }
  }
`;
