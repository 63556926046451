import { css } from 'styled-components';

import { ThemeProps as Prop, ColorProfiles } from '@powdr/constants';
// TODO: Convert all @includes from the `powdr-ui` core scss `client/assets/core/scss/_core.scss`
const global = css`
  * {
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
    white-space: normal;
    &:focus {
      outline: 0;
    }
  }

  html, body {
    overflow-x: clip;
  }

  body {
    /* *font-size:small; */
    /* *font:x-small; */
    line-height: 1;
    -webkit-overflow-scrolling: touch;
    background-color: ${({ theme }) => theme.defaultTheme[ColorProfiles.BASE][Prop.BACKGROUND]};
    color: ${({ theme }) => theme.defaultTheme[ColorProfiles.BASE][Prop.CONTENT]};
  }

  h1, h2, h3, h4, h5, h6 {
    line-height: 1;
  }


  input,textarea {
    @include user-select(auto);
    @include bp-mobile {
      @include user-select(text);
    }
  }

  a {
    /* @include transition(color .25s ease-out, background-color .25s ease-out); */
    cursor: pointer;
    text-decoration: none;
    /* @include bp-mobile-tablet {
      @include transition(none);
    } */
  }

  button {
    /* @include border-radius(0); */
    background-color: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    margin: 0;
    overflow: visible;
  }

  nav {
    position: relative;
  }

  figure, li {
    position: relative;
  }

  cite {
    display: block;
  }

  .bullet-points {
    list-style: disc outside none;
    margin-left: 15px;
    margin-bottom: 5px;
  }

  img {
    max-width: 100%;
  }

  @media only screen and (max-width: ${960 - 1}px) {
    button.satisfi_chat-button {
      bottom: 90px !important;
      //padding-bottom: 100px !important;
    }

    .trchat-bottom-right {
      bottom: 120px !important;
    }
  }

  .flex-2 {
    flex: 2 !important;
  }

  .row {
    margin-right: 0;
    margin-left: 0;
  }

  // PRINT
  @media print {
    body * {
      height: auto;
    }

    primary-header,
    primary-footer,
    global-footer,
    sticky-nav,
    .content-block-hero,
    .cms-level-1-footer,
    .static-page-wrapper,
    .homepage-container,
    .level-1-only,
    .tabs,
    .routing-setup {
      display: none;
    }

    .container,
    .level-2-page-title-container {
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding-left: 0 !important;
      padding-right: 0 !important;
    }

    .ui-section {
      margin: 0 !important;
      padding: 0 !important;
    }

    .content-block-reveal-horizontal * {
      padding: 0 !important;
      margin: 0 !important;

      figure div.right figcaption {
        padding: 0 !important;
      }
    }

    .content-block-reveal-horizontal {
      .left, .right {
        display: block;
        margin-bottom: 15px;
        width: 100%;
        img {
          margin-bottom: 15px;
        }
      }
    }

    .inner-page,
    .inner-page-container,
    .primary-main,
    .primary-wrapper,
    .wrapper,
    .main-wrapper {
      position: relative !important;
      height: auto !important;
      overflow-y: auto !important;
    }

    .primary-main,
    .main-wrapper {
      width: 100% !important
    }

    // utils override
    .cms-level-1-header .ui-section.u-p-t-50 {
      padding-top: 0 !important;
    }

    .cms-level-1-header .ui-section.u-p-b-50 {
      padding-bottom: 0 !important;
    }
  }

  // force hide recaptcha tag (Snowbird request)
  .grecaptcha-badge {
    display: none !important;
  }
`;

export default global;
